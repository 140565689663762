import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField, { ValidationRules, TextFieldType } from '../../../ui/input/TextField';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { ICustomer } from './Customer.type';

interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}

interface InputInterface {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}
interface FormElement {
  customerName: InputInterface;
}
interface IsValidReturnType {
  hasError: boolean;
  errorText: string;
}
interface Props {
  showDialog: boolean;
  closeDialog: () => void;
  customerAdded: (customer: ICustomer) => void;
}

const CreateCustomerDialog: React.FC<Props> = ({ showDialog, closeDialog, customerAdded }) => {
  const [generalError, setGeneralError] = React.useState('');
  const axiosPrivate = useAxiosPrivate();

  const closeDialogHandler = () => {
    closeDialog();
  };

  const isInputValid = (value: string | number, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };

    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };

  const [createCustomerFormElement, updateInputFormElements] = useState<FormElement>({
    customerName: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Name cannot be empty',
        },
      ],
      error: false,
      errorText: '',
    },
  });
  const inputChangeHandler = (newValue: string | number, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createCustomerFormCopy: any = { ...createCustomerFormElement };
    const updatedElement = { ...createCustomerFormCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    createCustomerFormCopy[itemIdentifier] = updatedElement;
    updateInputFormElements(createCustomerFormCopy);
  };

  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createCustomerFormCopy: any = { ...createCustomerFormElement };

    Object.entries(createCustomerFormElement).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = { ...createCustomerFormCopy[itemIdentifier] };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        createCustomerFormCopy[itemIdentifier] = updatedElement;
      }
    });
    updateInputFormElements(createCustomerFormCopy);
    return isValid;
  };

  interface ICreateCustomerResponse {
    statusCode: number;
    data?: ICustomer;
  }

  async function createCustomer(customerName: string): Promise<ICreateCustomerResponse> {
    return await axiosPrivate
      .post(
        '/customers',
        { name: createCustomerFormElement.customerName.value },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .then((response) => {
        return {
          statusCode: response.status,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          statusCode: error.status,
          data: error.response,
        };
      });
  }

  const createCustomerHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isFormValidFrontend()) {
      const customer = await createCustomer(createCustomerFormElement.customerName.value);
      if (customer.statusCode !== 201 || !customer.data) {
        setGeneralError('Someting went wrong');
      } else {
        closeDialog();
        customerAdded(customer.data);
      }
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={showDialog}
        onClose={closeDialogHandler}
        aria-labelledby="Create customer dialog"
      >
        <form onSubmit={createCustomerHandler}>
          <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#f7f7f7' }}>
            Create customer
          </DialogTitle>
          <hr />
          <DialogContent className="pt-5">
            <div>
              <TextField
                className="mb-3"
                id="customerName"
                label="Name"
                error={createCustomerFormElement.customerName.error}
                helperText={createCustomerFormElement.customerName.errorText}
                type={TextFieldType.text}
                value={createCustomerFormElement.customerName.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'customerName')}
              />
            </div>
            {generalError && (
              <div
                className="mt-3 "
                style={{
                  backgroundColor: '#FFCDD2',
                  border: '1px solid #EF9A9A',
                  padding: '8px',
                  borderRadius: '3px',
                }}
              >
                {generalError}
              </div>
            )}
          </DialogContent>
          <hr />
          <DialogActions className="mt-1 mb-1">
            <Button
              type={ButtonType.button}
              buttonStyleType={ButtonStyleType.secondary}
              text="Cancel"
              onClick={closeDialogHandler}
            />
            <Button
              type={ButtonType.submit}
              buttonStyleType={ButtonStyleType.primary}
              text="Create"
              onClick={createCustomerHandler}
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
CreateCustomerDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  customerAdded: PropTypes.func.isRequired,
};
export default CreateCustomerDialog;
