import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiEarth, mdiCash, mdiInformationOutline, mdiFileDownloadOutline } from '@mdi/js';
import { Line, Doughnut } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';
import deepCopy from 'deepcopy';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import exportReport from './ExportReport/ExportReport';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import classes from './ReportComponent.module.scss';
import TooltipPrognos from '../../ui/tooltip/Tooltip';
import IconButton from '../../ui/iconButton/IconButton';
import Legend, { LegendInterFace } from './Legend/Legend';
import Breadcrumb, { BreadcrumbInterface } from './Breadcrumb/BredCrumb';
import { ChartData as ChartDataPrognos, ChartLineExtended, IReport, IReference, TimeSeries } from './types';
import ReferenceMenu from './ReferencesMenu';
import { Button } from '@mui/material';
import { ChartData } from 'chart.js';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  references: IReference[];
  report: IReport;
}

const ReportComponent: React.FC<Props> = ({ references, report }) => {
  const { search, pathname } = useLocation();
  const urlNestedChartIds = new URLSearchParams(search).getAll('c');

  const colorFromUrl = new URLSearchParams(search).get('co');
  // eslint-disable-next-line

  const [constantReportState, setConstantReportState] = useState<IReport>();

  const [hasCostDrivers, setHasCostDrivers] = useState<boolean>(false);
  const [showCostDrivers, setShowCostDrivers] = useState<boolean>(false);

  const [chartData, setChartData] = useState<ChartDataPrognos | null>(null);

  const [referenceState, setReferenceState] = useState<IReference[]>([]);

  const [legendsState, setLegendsState] = useState<LegendInterFace[]>([]);
  const [breadcrumbsState, setBreadcrumbState] = useState<BreadcrumbInterface[]>([]);

  const colorsTimeSeries = [
    'rgb(0,130,190)',
    'rgb(15,130,55)',
    'rgb(40,50,120)',
    'rgb(120,10,25)',
    'rgb(220,135,5)',
    'rgb(230,215,0)',
    'rgb(125,165,35)',
    'rgb(25,140,130)',
    'rgb(10,95,155)',
    'rgb(130,30,115)',
    'rgb(200,0,110)',
    'rgb(190,10,70)',
  ];

  const [reportInformation, setReportInformation] = useState({
    description: '',
    currency: '',
    country: '',
  });

  const createBredCrumb = (reportData: IReport) => {
    let baseTimeSerie = reportData.benchmarkTimeSerie;
    const breadcrumbToSave: BreadcrumbInterface[] = [
      {
        name: baseTimeSerie?.name ?? '',
        linkTo: pathname,
      },
    ];
    let colorIndex = 0;

    for (let index = 0; index < urlNestedChartIds.length; index += 1) {
      const baseLineToSave = baseTimeSerie?.costDrivers?.find(
        (costDriver) => costDriver.id === urlNestedChartIds[index]
      );

      const position = baseTimeSerie?.costDrivers?.findIndex(
        (costDriver) => costDriver.id === urlNestedChartIds[index]
      );
      if (position !== undefined && position !== -1) {
        colorIndex += position + 1;
        if (colorsTimeSeries.length <= colorIndex) {
          colorIndex = 0;
        }
      }
      if (baseLineToSave) {
        const breadcrumbBetween = breadcrumbToSave.length < 2 ? '?c=' : '&c=';
        breadcrumbToSave.push({
          name: baseLineToSave.name,
          linkTo: `${pathname}${breadcrumbBetween}${baseLineToSave.id}&co=${colorsTimeSeries[colorIndex]}`,
        });
      }

      if (baseLineToSave) {
        baseTimeSerie = baseLineToSave;
      }
    }
    setBreadcrumbState(breadcrumbToSave);
  };

  const createChartData = (reportData: IReport | null = null) => {
    let baseTimeSerie: TimeSeries;
    let colorIndex = 0;

    const referencesLinesToShow: IReference[] = referenceState
      .filter((reference) => reference.checked)
      .map((item) => {
        const returnValue = { ...item };
        returnValue.calculatedData = item.calculatedData ? [...item.calculatedData] : [];
        return returnValue;
      });

    if (reportData && reportData?.benchmarkTimeSerie) {
      createBredCrumb(reportData);
      baseTimeSerie = reportData?.benchmarkTimeSerie;
      if (colorFromUrl) {
        const indexColor = colorsTimeSeries.findIndex((c) => c === colorFromUrl);
        if (indexColor !== -1) {
          colorIndex = indexColor;
        }
      }
      if (urlNestedChartIds.length > 0) {
        for (let index = 0; index < urlNestedChartIds.length; index += 1) {
          const baseLineToSave = baseTimeSerie.costDrivers?.find(
            (costDriver) => costDriver.id === urlNestedChartIds[index]
          );
          if (baseLineToSave) {
            baseTimeSerie = baseLineToSave;
          }
        }
      }
      if (baseTimeSerie.costDrivers && baseTimeSerie.costDrivers.length > 0) {
        setHasCostDrivers(true);
      } else {
        setHasCostDrivers(false);
      }
      setReportInformation({
        description: baseTimeSerie.description || '',
        country: baseTimeSerie.country || '',
        currency: baseTimeSerie.currency || '',
      });
    }

    const createLegendData = (timeSeries: TimeSeries, color: string, mainItem = false): LegendInterFace => ({
      id: timeSeries.id,
      description: timeSeries.description,
      name: timeSeries.name,
      country: timeSeries.country,
      currency: timeSeries.currency,
      share: mainItem ? undefined : timeSeries.share,
      color,
    });
    const legendsToSave: LegendInterFace[] = [];
    const mapToTableValue = () => {
      let chartDataValue: ChartLineExtended[] = [];
      chartDataValue = [];
      if (baseTimeSerie && reportData) {
        legendsToSave.push(createLegendData(baseTimeSerie, colorsTimeSeries[colorIndex], true));

        const chartDataLines = baseTimeSerie?.data?.map((data) => {
          return {
            y: data.y,
            x: data.x,
          };
        });
        chartDataValue.push({
          id: baseTimeSerie.id,
          name: baseTimeSerie.name,
          label: baseTimeSerie.id,
          pointRadius: showCostDrivers || (referencesLinesToShow && referencesLinesToShow.length > 0) ? 2 : 2,
          borderWidth: showCostDrivers || (referencesLinesToShow && referencesLinesToShow.length > 0) ? 4 : undefined,
          backgroundColor: colorsTimeSeries[colorIndex],
          borderColor: colorsTimeSeries[colorIndex],
          data: chartDataLines || [],
          fill: false,
        });
        if (showCostDrivers && baseTimeSerie.costDrivers) {
          baseTimeSerie.costDrivers.forEach((costDriver) => {
            colorIndex += 1;
            legendsToSave.push(createLegendData(costDriver, colorsTimeSeries[colorIndex]));
            const chartLine: ChartLineExtended = {
              id: costDriver.id,
              name: costDriver.name,
              label: baseTimeSerie.id + costDriver.id,
              pointRadius: 1.9,
              backgroundColor: colorsTimeSeries[colorIndex],
              borderColor: colorsTimeSeries[colorIndex],
              data: costDriver?.data ?? [],
              fill: false,
            };
            chartDataValue.push(chartLine);
          });
        }
      }
      return chartDataValue;
    };

    const chartDataToSave = mapToTableValue();

    if (referencesLinesToShow && referencesLinesToShow.length > 0) {
      const referenceColors = [
        'rgb(75,75,75)',
        'rgb(105,105,105)',
        'rgb(135,135,135)',
        'rgb(215,215,215)',
        'rgb(175,175,175)',
      ];
      let colorReferenceIndex = 0;
      referencesLinesToShow.forEach((referenceItem) => {
        legendsToSave.push(createLegendData(referenceItem, referenceColors[colorReferenceIndex], false));
        const startDate = new Date(referenceItem.startDate ?? '');
        startDate.setHours(0, 0, 0, 0);

        const chartLine: ChartLineExtended = {
          id: referenceItem.id,
          name: referenceItem.name,
          label: referenceItem.id,
          pointRadius: 1.9,
          backgroundColor: referenceColors[colorReferenceIndex],
          borderColor: referenceColors[colorReferenceIndex],
          data: referenceItem?.calculatedData ?? [],
          fill: false,
        };
        chartDataToSave.push(chartLine);
        colorReferenceIndex += 1;
        if (!referenceColors[colorReferenceIndex]) colorReferenceIndex = 0;
      });
    }
    const sortAllDates = (datasets: ChartLineExtended[]) => {
      const datasetsCopy = deepCopy(datasets);
      const dataSets = datasetsCopy.map((x) => x.data);
      const dataSetsFlat = dataSets.flat();
      const datesSorted = dataSetsFlat.sort(
        (a, b) => Date.parse(a?.x.toString() ?? '') - Date.parse(b?.x.toString() ?? '')
      );
      return {
        firstDate: datesSorted[0]?.x,
        lastDate: datesSorted.slice(-1)[0]?.x,
      };
    };
    const dates = sortAllDates(chartDataToSave);
    if (dates?.firstDate && dates?.lastDate) {
      const firstDate = new Date(dates.firstDate);
      const lastDate = new Date(dates.lastDate);
      const chartDataToSaveCopy: ChartDataPrognos = {
        labels: [firstDate, lastDate],
        startDate: firstDate,
        lastDate: lastDate,
        datasets: fillOutDatasets(chartDataToSave, firstDate, lastDate),
      };
      setChartData(chartDataToSaveCopy);
      setLegendsState(legendsToSave);
    }
  };
  const fillOutDatasets = (datasets: ChartLineExtended[], firstDate: Date, lastDate: Date): ChartLineExtended[] => {
    const filledOutDatasets: ChartLineExtended[] = [];
    datasets.forEach((ds) => {
      const filledDataset = [];
      let date = firstDate;

      while (new Date(date) <= new Date(lastDate)) {
        const dateToMatch = new Date(date).setHours(0, 0, 0, 0);
        const matchingDataPoint = ds.data.filter((x) => {
          const isMatching = new Date(x.x).setHours(0, 0, 0, 0) === dateToMatch;
          return isMatching;
        });
        if (matchingDataPoint.length === 1) {
          filledDataset.push({
            x: matchingDataPoint[0].x,
            y: matchingDataPoint[0].y,
          });
        } else {
          filledDataset.push({
            x: date.toLocaleString('sv-SE', { dateStyle: 'short' }),
            y: null,
          });
        }
        date = new Date(new Date(date).setMonth(new Date(date).getMonth() + 1));
      }
      ds.data = filledDataset;
      filledOutDatasets.push(ds);
    });
    return filledOutDatasets;
  };

  const setReferences = (references: IReference[]) => {
    const referencesCopy = references.map((reference) => ({
      ...reference,
      checked: false,
    }));
    setReferenceState(referencesCopy);
  };

  useEffect(() => {
    if (report) {
      setConstantReportState(report);
      createChartData(report);
    }

    if (references) {
      setReferences(references);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (constantReportState) {
      createChartData(constantReportState);
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  let country: JSX.Element | null = null;
  if (reportInformation.country?.trim() !== '') {
    country = (
      <div className={classes.infoText}>
        <Icon path={mdiEarth} title="Earth icon" size={1} />
        <span>{reportInformation.country}</span>
      </div>
    );
  }

  let currency: JSX.Element | null = null;

  if (reportInformation.currency?.trim() !== '') {
    currency = (
      <div className={classes.infoText}>
        <Icon path={mdiCash} title="Currency icon" size={1} />
        <span>{reportInformation.currency}</span>
      </div>
    );
  }

  let description: JSX.Element | null = null;
  if (reportInformation.description?.trim() !== '') {
    description = (
      <div className={classes.footerDescriptionContainer}>
        <TooltipPrognos title={reportInformation.description}>
          <div className={`${classes.footerDescription}`}>
            <Icon path={mdiInformationOutline} title="information icon" size={0.9} />
            {reportInformation.description}
          </div>
        </TooltipPrognos>
      </div>
    );
  }

  let legendsElement: JSX.Element[] | null = null;

  if (chartData) {
    legendsElement = legendsState.map((legend) => (
      <Legend
        key={legend.id}
        name={legend.name}
        id={legend.id}
        share={legend.share}
        description={legend.description}
        country={legend.country}
        currency={legend.currency}
        color={legend.color}
      />
    ));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartOptions: any = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label(labelItem: any) {
            //
            const labelItemNumber = Number(labelItem.raw.y).toFixed(2).toString();

            return labelItemNumber + ' - ' + labelItem?.dataset?.name || '';
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title(tooltipItem: any) {
            let titleDate: string | Date = '';
            if (typeof tooltipItem[0]?.raw.x === 'string') {
              const dateString = new Date(tooltipItem[0]?.raw.x);
              if (dateString instanceof Date) {
                const monthShort = dateString.toLocaleDateString('en-US', {
                  month: 'short',
                });
                titleDate = `${monthShort} ${dateString.getFullYear()}`;
              }
            }
            return titleDate;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'timeseries',
        ticks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: function (val: any, index: any, dates: any): any {
            if (index !== 0 && index !== dates.length - 1) {
              if (dates.length < 12) return null;
              const indexItemToShow = Math.round(dates.length / 10);
              if (dates.length - index < indexItemToShow) return null;
              if (index % indexItemToShow !== 0) return null;
            }
            const date = new Date(val);
            const tickValue = date.toLocaleString('en-US', { month: 'short' }) + ' ' + date.getFullYear();
            return tickValue;
          },
        },
        time: {
          displayFormats: {
            quarter: 'MMM YYYY',
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: constantReportState?.yLabelName,
          font: {
            size: 10,
            lineHeight: 1.2,
          },
          padding: {
            bottom: 14,
          },
        },
      },
    },
  };

  const exportChartDataHandler = () => {
    if (chartData) {
      exportReport(chartData, referenceState, showCostDrivers, urlNestedChartIds, constantReportState?.name || '');
    }
  };
  const toggleCostDriversHandler = () => {
    setShowCostDrivers(!showCostDrivers);
  };

  useEffect(() => {
    if (constantReportState) {
      createChartData(constantReportState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCostDrivers]);

  const checkboxReferenceHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const referencesNewValue = referenceState.map((reference) => {
      const referenceCopy = reference;
      if (referenceCopy.id === event.target.value) {
        referenceCopy.checked = !referenceCopy.checked;
      }
      return referenceCopy;
    });
    setReferenceState(referencesNewValue);
    createChartData(constantReportState);
  };
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const setZoom = (datasets: ChartLineExtended[]) => {
    const dataSetsCopy = deepCopy(datasets);

    const returnData = dataSetsCopy.map((dataset) => {
      const newDataset = dataset.data.filter((dataSerie) => {
        const dateToCompare = new Date(dataSerie.x);
        if ((fromDate && isSameDay(dateToCompare, fromDate)) || (toDate && isSameDay(dateToCompare, toDate))) {
          return true;
        }
        if (fromDate && !isAfter(dateToCompare, fromDate)) {
          return false;
        }
        if (toDate && !isBefore(dateToCompare, toDate)) {
          return false;
        }

        return true;
      });
      return { ...dataset, data: newDataset };
    });
    return returnData;
  };

  let doughnutElement: JSX.Element | null = null;

  if (showCostDrivers) {
    const options = {
      responsive: true,
      legend: {
        display: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label(labelItem: any) {
              const labelItemNumber = (100 * labelItem.raw).toString() + '%';
              return labelItemNumber;
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          },
        },
      },
    };
    const doughnutChartColors: string[] = [];
    const doughnutChartValues: number[] = [];
    legendsState
      .filter((c) => c.share)
      .forEach((x) => {
        if (x.color && x.share) {
          doughnutChartColors.push(x.color);
          doughnutChartValues.push(x.share);
        }
      });
    const doughnutChartData: ChartData<'doughnut', number[], unknown> = {
      datasets: [
        {
          backgroundColor: doughnutChartColors,
          data: doughnutChartValues,
        },
      ],
    };

    doughnutElement = (
      <div className="floatRight" style={{ maxWidth: '150px' }}>
        <Doughnut data={doughnutChartData} options={options} />
      </div>
    );
  }
  const resetLineChartZoom = () => {
    setToDate(null);
    setFromDate(null);
  };

  return (
    <>
      <div className={classes.cardContainer}>
        <header>
          <h2>{constantReportState?.name}</h2>
          <div className={`${classes.headerRightContainer} floatRight`}>
            {chartData && (
              <IconButton
                areaLabel="Export chart data"
                icon={mdiFileDownloadOutline}
                onClick={exportChartDataHandler}
              />
            )}
          </div>
        </header>
        <hr className={`${classes.fullWidthHr} mb-1 mt-3`} />
        <div className={classes.chartTopSettings}>
          <Breadcrumb breadcrumb={breadcrumbsState} />
          {!chartData?.datasets && <h3>No report data found</h3>}
          <div className="floatRight">
            {hasCostDrivers && (
              <Button
                style={{ textTransform: 'none' }}
                variant="outlined"
                className={`${classes.actionButton} ${showCostDrivers ? classes.checkedButton : ''}`}
                onClick={() => toggleCostDriversHandler()}
              >
                Cost drivers
              </Button>
            )}
            <ReferenceMenu checkboxReferenceHandler={checkboxReferenceHandler} references={referenceState} />
          </div>
        </div>

        {chartData && (
          <>
            <Line
              data={{
                datasets: chartData?.datasets ? setZoom(chartData?.datasets) : [],
              }}
              options={chartOptions}
            />
          </>
        )}
        <div className={`${classes.zoomOptionContainer}`}>
          <div className={` ${classes.zoomOption}`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                value={fromDate ? dayjs(fromDate) : null}
                openTo={'year'}
                views={['year', 'month']}
                minDate={chartData?.startDate ? dayjs(chartData?.startDate) : undefined}
                maxDate={toDate ? dayjs(toDate) : chartData?.lastDate ? dayjs(chartData?.lastDate) : undefined}
                onChange={(date: Dayjs | null) => {
                  if (!date) {
                    setFromDate(null);
                    return;
                  }
                  const dateToSave = date ? date.toDate() : null;
                  setFromDate(dateToSave);
                }}
              />
              <DatePicker
                label="To"
                value={toDate ? dayjs(toDate) : null}
                openTo={'year'}
                views={['year', 'month']}
                minDate={fromDate ? dayjs(fromDate) : chartData?.startDate ? dayjs(chartData?.startDate) : undefined}
                maxDate={chartData?.lastDate ? dayjs(chartData.lastDate) : undefined}
                onChange={(date: Dayjs | null) => {
                  if (!date) {
                    setToDate(null);
                    return;
                  }
                  const dateToSave = date ? date.toDate() : null;
                  setToDate(dateToSave);
                }}
              />
            </LocalizationProvider>
          </div>
          {chartData?.datasets && (
            <Button
              className={`${classes.actionButton} ${classes.zoomOption}`}
              style={{ textTransform: 'none' }}
              variant="outlined"
              onClick={() => resetLineChartZoom()}
            >
              Show all
            </Button>
          )}
        </div>
        <div className={`${classes.footerChartDescription} mt-3 mb-3`}>
          {country}
          {currency}
          {description}
        </div>
        <hr className="mb-4" />
        <div>
          {doughnutElement}
          {legendsElement}
        </div>
      </div>
    </>
  );
};
export default ReportComponent;
