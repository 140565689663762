/* eslint-disable jsx-a11y/no-access-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import deepCopy from 'deepcopy';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import classes from './Users.module.scss';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import ConfirmDialog, { ConfirmDialogType } from '../../../components/dialog/ConfirmDialog';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import axios from 'axios';

interface IUser {
  userId: string;
  email: string;
  lastLoginDate: Date;
  signUpDate: Date;
}

interface Props {
  customerId: string;
  customerName: string;
}
const confirmDataConstant = {
  dialogText: '',
  errorMessage: '',
  buttonTextAccept: '',
  buttonTextDismiss: '',
  dialogTitle: '',
  isLoading: false,
  showDialog: false,
  submitDialog: () => undefined,
};

const AdminUsers: React.FC<Props> = ({ customerId, customerName }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogType>(confirmDataConstant);
  const [searchText, setSearchText] = useState('');
  const [itemsToShow, setItemsToShow] = useState<IUser[]>([]);
  const axiosPrivate = useAxiosPrivate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [userRemoved, setUserRemoved] = useState<number>(0);

  const newSearchInput = (searchTextInput: string, userSorted?: IUser[]) => {
    setSearchText(searchTextInput);
    const userCopy = userSorted || deepCopy(users);
    const filteredUsers = userCopy.filter((userFilter) => {
      const searchTextLower = searchTextInput.toLocaleLowerCase();
      if (!searchTextInput) return true;
      if (userFilter.email.toLocaleLowerCase().includes(searchTextLower)) {
        return true;
      }

      return false;
    });
    setItemsToShow(filteredUsers);
  };

  const sortCustomerByEmail = (sharedReports: IUser[]) => {
    const reportCopy = deepCopy(sharedReports);
    return reportCopy.sort((a, b) => {
      const nameA = a.email?.toUpperCase();
      const nameB = b.email?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };
  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getUserData() {
      await axiosPrivate
        .get('/users/getUsersForCompany', {
          params: {
            customerId: customerId,
          },
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        })
        .then((response) => {
          const users: IUser[] = response.data;
          if (users) {
            const sortedUsers = sortCustomerByEmail(users);
            setUsers(sortedUsers);
            newSearchInput(searchText, sortedUsers);
          }
        })
        .catch((error) => {
          if (error?.code === 'ERR_CANCELED') {
            return;
          }
          setErrorMessage('Something went wrong try again later.');
        });
    }
    getUserData();

    return () => {
      source.cancel('Cancelling in cleanup');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, userRemoved]);

  async function removeUserFromCompany(user: IUser, confirmDialogValues: ConfirmDialogType): Promise<string> {
    let dialogUserCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogUserCopy);

    try {
      await axiosPrivate
        .post(
          '/users/removeUserFromCompany',
          {
            userId: user.userId,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then(() => {
          setUserRemoved((userRemove) => (userRemove += 1));
          dialogUserCopy = { ...confirmDialogValues, isLoading: false };
          setConfirmDialogState(dialogUserCopy);
          closeConfirmAlertDialog();
        })
        .catch(() => {
          dialogUserCopy = { ...confirmDialogValues, errorMessage: 'Something went wrong', isLoading: false };
          setConfirmDialogState(dialogUserCopy);
          return;
        });
    } catch (error) {
      dialogUserCopy = { ...confirmDialogValues, errorMessage: 'Something went wrong', isLoading: false };
      setConfirmDialogState(dialogUserCopy);
    }

    return '';
  }
  function confirmRemoveUserDialog(user: IUser): void {
    const values = {
      dialogTitle: 'Confirm remove user',
      dialogText: `Are you sure you want to remove "${user.email}" from ${customerName}? (Will change the user to demo company)  `,
      buttonTextAccept: 'Remove',
      showDialog: true,
      submitDialog: () => removeUserFromCompany(user, confirmDialogState),
    };
    values.submitDialog = () => removeUserFromCompany(user, values);

    setConfirmDialogState(values);
  }

  const closeConfirmAlertDialog = () => {
    const confirmAlertDataConstantCopy = { ...confirmDataConstant };
    setConfirmDialogState(confirmAlertDataConstantCopy);
  };
  return (
    <>
      <div>
        <div className={`${classes.tablePageHeader} mb-3`}>
          <h2>Users</h2>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              accessKey="f"
              onChange={(event) => newSearchInput(event.target.value)}
              value={searchText}
              style={{ width: '100%' }}
              placeholder="Search…"
              className={classes.inputInput}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </div>
        {errorMessage && <div className="errorText">{errorMessage}</div>}
        {itemsToShow.map((user) => {
          return (
            <div className={classes.userBox} key={user.userId}>
              <Icon className="mr-1 ml-2" color="grey" path={mdiAccount} title="User" size={1.5} />
              <div className="ml-4">
                <strong>{user.email}</strong>
                <span>Signed up:</span>
                <span className="ml-1">{new Date(user.signUpDate).toDateString()}</span>
                <br />
                <span>Last logged in:</span>
                <span className="ml-1">{new Date(user.lastLoginDate).toDateString()}</span>
              </div>
              {customerName !== 'Demo customer' && (
                <div className={`${classes.removeContainer} ml-1`}>
                  {customerId !== 'demo' && (
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.delete}
                      text="Remove"
                      onClick={() => confirmRemoveUserDialog(user)}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <ConfirmDialog
        isLoading={confirmDialogState?.isLoading || false}
        errorMessage={confirmDialogState.errorMessage}
        submitDialog={confirmDialogState.submitDialog}
        showDialog={confirmDialogState.showDialog}
        closeDialog={closeConfirmAlertDialog}
        buttonTextAccept={confirmDialogState?.buttonTextAccept}
        dialogText={confirmDialogState?.dialogText}
      />
    </>
  );
};

AdminUsers.propTypes = {
  customerId: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

export default AdminUsers;
