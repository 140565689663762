/* eslint-disable jsx-a11y/no-access-key */
import React, { useEffect, useState } from 'react';
import deepCopy from 'deepcopy';

import { ICustomer } from './Customer.type';
import CreateCustomerDialog from './CreateCustomerDialog';
import PageHeader from '../../../components/pageHeader/PageHeader';

import Table, { TableHeader } from './CustomerTable/CustomersTable';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { mdiDomainPlus } from '@mdi/js';
import RouterPaths from '../../../router/RouterPaths';
import { useNavigate } from 'react-router-dom';

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tableItemsToShow, setTableItemsToShow] = useState<ICustomer[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const sortCustomers = (customerToSort: ICustomer[]) => {
    const customersCopy = deepCopy(customerToSort);
    return customersCopy.sort((a, b) => {
      const nameA = a.name?.toUpperCase();
      const nameB = b.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };
  const newSearchInput = (searchTextInput: string, customersSorted?: ICustomer[]) => {
    setSearchText(searchTextInput);
    const customerCopy = customersSorted || deepCopy(customers);
    const filteredCustomers = customerCopy.filter((customerFilter) => {
      const searchTextLower = searchTextInput.toLocaleLowerCase();
      if (!searchTextInput) return true;
      if (
        customerFilter.id.toLocaleLowerCase().includes(searchTextLower) ||
        customerFilter.name.toLocaleLowerCase().includes(searchTextLower)
      ) {
        return true;
      }

      return false;
    });
    setTableItemsToShow(filteredCustomers);
  };

  async function getCustomers() {
    setErrorMessage('');
    await axiosPrivate
      .get('/customers', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        const customers: ICustomer[] = response.data;
        const customersSorted = sortCustomers(customers);
        setCustomers(customersSorted);
        newSearchInput(searchText, customersSorted);
      })
      .catch((e) => {
        setErrorMessage('Something went wrong try again later.');
      });
  }

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createCustomer = () => {
    setShowDialog(true);
  };

  const closeCreateCustomerDialog = async () => {
    setShowDialog(false);
  };

  const customerAdded = (customer: ICustomer) => {
    const customerUrl = RouterPaths.adminCustomer.replace(':customerUrl', customer?.customerUrl);
    navigate(customerUrl);
  };

  const tableHeaders: TableHeader[] = [
    {
      id: 'name',
      name: 'Name',
      sortable: true,
      numeric: false,
    },
    {
      id: 'id',
      name: 'Key',
      sortable: true,
      numeric: false,
    },
  ];

  return (
    <>
      <PageHeader
        pageTitle="Customers"
        button={{ text: 'New Customer', onClick: createCustomer }}
        search={{ searchText, newSearchInput }}
        icon={{ iconPath: mdiDomainPlus, iconTitle: 'Add Customer Icon' }}
      />

      <div className="ml-2 mr-2">
        {errorMessage && <div className="errorText">{errorMessage}</div>}
        <Table tableHeaders={tableHeaders} tableItems={tableItemsToShow} />
        <div>
          Total: {tableItemsToShow.length}
          {' of '}
          {customers.length}
        </div>
        {showDialog && (
          <CreateCustomerDialog
            showDialog={showDialog}
            customerAdded={(customer) => customerAdded(customer)}
            closeDialog={closeCreateCustomerDialog}
          />
        )}
      </div>
    </>
  );
};

export default Customers;
