/* eslint-disable jsx-a11y/no-access-key */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import deepCopy from 'deepcopy';

import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import routerPaths from '../../../router/RouterPaths';

import ConfirmDialog, { ConfirmDialogType } from '../../../components/dialog/ConfirmDialog';
import NewReportDialog from '../Customer/NewReportDialog';
import PrognosSnackbar, { SnackbarType } from '../../../ui/snackbar/Snackbar';
import ReportsTable, { IReport, TableHeader } from '../Customer/ReportsTable';
import PageHeader from '../../../components/pageHeader/PageHeader';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { mdiFileDocumentPlusOutline } from '@mdi/js';
import axios, { AxiosError } from 'axios';

const SharedReports: React.FC = () => {
  const confirmDataConstant = {
    dialogText: '',
    errorMessage: '',
    buttonTextAccept: '',
    buttonTextDismiss: '',
    dialogTitle: '',
    isLoading: false,
    showDialog: false,
    submitDialog: () => undefined,
  };

  const axiosPrivate = useAxiosPrivate();

  const [searchText, setSearchText] = useState('');
  const [tableItemsToShow, setTableItemsToShow] = useState<IReport[]>([]);
  const [reportsState, setReportsState] = useState<IReport[]>([]);
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogType>(confirmDataConstant);
  const [snackbarState, setSnackbarState] = useState({
    title: '',
    message: '',
    showSnackbar: false,
  });
  const [showNewReportDialogState, setShowNewReportDialogState] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const sortReportByName = (sharedReports: IReport[]) => {
    const reportCopy = deepCopy(sharedReports);
    return reportCopy.sort((a, b) => {
      const nameA = a.name?.toUpperCase();
      const nameB = b.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };
  const newSearchInput = (searchTextInput: string, reportSorted?: IReport[]) => {
    setSearchText(searchTextInput);
    const reportCopy = reportSorted || deepCopy(reportsState);
    const filteredReports = reportCopy.filter((reportFilter) => {
      const searchTextLower = searchTextInput.toLocaleLowerCase();
      if (!searchTextInput) return true;
      if (
        reportFilter._id.toLocaleLowerCase().includes(searchTextLower) ||
        reportFilter.name.toLocaleLowerCase().includes(searchTextLower) ||
        reportFilter.currency.toLocaleLowerCase().includes(searchTextLower) ||
        reportFilter.country.toLocaleLowerCase().includes(searchTextLower)
      ) {
        return true;
      }

      return false;
    });
    setTableItemsToShow(filteredReports);
  };

  async function getSharedReports() {
    setErrorMessage('');
    await axiosPrivate
      .get('/reports/getSharedReportsForAdmin', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        const reports: IReport[] = response.data;
        if (reports) {
          const sortedReports = sortReportByName(reports);
          setReportsState(sortedReports);
          newSearchInput(searchText, sortedReports);
        }
      })
      .catch((e: AxiosError | unknown) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401) {
            return;
          }
        }
        setErrorMessage('Something went wrong try again later.');
      });
  }

  useEffect(() => {
    getSharedReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteReport = async (report: IReport, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);

    await axiosPrivate
      .delete(`/reports/${report._id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((reponse) => {
        setConfirmDialogState({ ...confirmDataConstant });
        setSnackbarState({
          title: '',
          message: `Report "${report.name}" is deleted.`,
          showSnackbar: true,
        });

        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((error) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: 'Something went wrong',
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      })
      .finally(() => {
        getSharedReports();
      });
  };
  const confirmDeleteReport = (report: IReport) => {
    const values = {
      dialogTitle: 'Confirm',
      errorMessage: '',
      dialogText: `${'Are you sure you want to delete "'}${report.name}" ?`,
      buttonTextAccept: 'Delete',
      showDialog: true,
      submitDialog: () => deleteReport(report, confirmDialogState),
    };
    values.submitDialog = () => deleteReport(report, values);

    setConfirmDialogState(values);
  };

  const closeConfirmAlertDialog = () => {
    const confirmAlertDataConstantCopy = { ...confirmDataConstant };
    setConfirmDialogState(confirmAlertDataConstantCopy);
  };

  const reportToPublish = async (report: IReport, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);
    await axiosPrivate
      .patch(`reports/publish/${report._id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        setConfirmDialogState({ ...confirmDataConstant });
        setSnackbarState({
          title: '',
          message: `Report "${report.name}" is now published.`,
          showSnackbar: true,
        });
        getSharedReports();
        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((e) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: 'Could not publish report',
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      });
  };

  const confirmReportToPublish = (report: IReport) => {
    const values = {
      dialogTitle: 'Confirm',
      errorMessage: '',
      dialogText: `${'Are you sure you want to publish "'}${report.name}" ?`,
      buttonTextAccept: 'Publish',
      showDialog: true,
      submitDialog: () => reportToPublish(report, confirmDialogState),
    };
    values.submitDialog = () => reportToPublish(report, values);

    setConfirmDialogState(values);
  };

  const unpublish = async (report: IReport, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);

    await axiosPrivate
      .patch(`reports/unpublish/${report._id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        setConfirmDialogState({ ...confirmDataConstant });
        setSnackbarState({
          title: '',
          message: `Report "${report.name}" is now unpublished.`,
          showSnackbar: true,
        });
        getSharedReports();
        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((e) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: 'Could not publish report',
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      });
  };

  const confirmUnpublish = (report: IReport) => {
    const values = {
      dialogTitle: 'Confirm',
      dialogText: `${'Are you sure you want to unpublish "'}${report.name}" ?`,
      buttonTextAccept: 'Unpublish',
      showDialog: true,
      submitDialog: () => unpublish(report, confirmDialogState),
    };
    values.submitDialog = () => unpublish(report, values);

    setConfirmDialogState(values);
  };

  const openNewReportDialog = () => {
    setShowNewReportDialogState(true);
  };
  const closeNewReportDialog = () => {
    setShowNewReportDialogState(false);
  };

  const newReportCreated = () => {
    getSharedReports();
    setSnackbarState({
      title: 'Report created',
      message: 'The report was created successfully',
      showSnackbar: true,
    });
    setTimeout(() => {
      setSnackbarState({ title: '', message: '', showSnackbar: false });
    }, 5000);
  };
  const tableHeaders: TableHeader[] = [
    {
      id: 'name',
      name: 'Name',
      sortable: true,
      numeric: false,
    },
    {
      id: 'country',
      name: 'Country',
      sortable: true,
      numeric: false,
    },
    {
      id: 'currency',
      name: 'Currency',
      sortable: true,
      numeric: false,
    },
    {
      id: 'hidden',
      name: 'Hidden',
      sortable: true,
      numeric: false,
    },
    {
      id: 'firstDatePointDate',
      name: 'Date from',
      sortable: true,
      numeric: false,
    },
    {
      id: 'lastDataPointDate',
      name: 'Date to',
      sortable: true,
      numeric: false,
    },
  ];
  return (
    <>
      <PageHeader
        pageTitle="Shared Reports"
        button={{ text: 'New Report', onClick: openNewReportDialog }}
        search={{ searchText, newSearchInput }}
        icon={{ iconPath: mdiFileDocumentPlusOutline, iconTitle: 'Add Report Icon' }}
      />

      <div className="ml-2 mr-2">
        {errorMessage && <div className="errorText">{errorMessage}</div>}
        <h2 className="mb-2">Drafts</h2>
        <ReportsTable
          tableHeaders={tableHeaders}
          tableItems={
            tableItemsToShow &&
            tableItemsToShow
              .filter((x) => x.isDraft)
              .map((tableItem) => ({
                id: tableItem._id,
                name: tableItem.name,
                country: tableItem.country,
                currency: tableItem.currency,
                firstDatePointDate: tableItem.firstDatePointDate
                  ? new Date(tableItem.firstDatePointDate).toLocaleDateString('sv-SE')
                  : '',
                lastDataPointDate: tableItem.lastDataPointDate
                  ? new Date(tableItem.lastDataPointDate).toLocaleDateString('sv-SE')
                  : '',
                hidden: tableItem.hideReportFromUserReportList ? 'hidden' : '',
                isReportValid: <>{!tableItem.isReportValid && <div className="isValidError">Error in report!</div>}</>,
                actions: (
                  <>
                    <Link className="ml-3" to={routerPaths.reportEdit.replace(':reportUrl', tableItem.reportUrl)}>
                      <Button small type={ButtonType.button} buttonStyleType={ButtonStyleType.secondary} text="Edit" />
                    </Link>
                    <Link
                      className="ml-3"
                      to={routerPaths.report
                        .replace(':customerUrl', 'shared')
                        .replace(':reportUrl', tableItem.reportUrl)}
                    >
                      <Button
                        small
                        type={ButtonType.button}
                        buttonStyleType={ButtonStyleType.secondary}
                        text="Preview"
                      />
                    </Link>
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.primary}
                      text="Publish"
                      onClick={() => confirmReportToPublish(tableItem)}
                    />
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.delete}
                      text="X"
                      onClick={() => confirmDeleteReport(tableItem)}
                    />
                  </>
                ),
              }))
          }
          hasActions
          totalItems={reportsState.filter((i) => i.isDraft).length}
        />
        <h2 className="mt-6 mb-2">Published Reports</h2>
        <ReportsTable
          tableHeaders={tableHeaders}
          tableItems={
            tableItemsToShow &&
            tableItemsToShow
              .filter((x) => !x.isDraft)
              .map((tableItem) => ({
                id: tableItem._id,
                name: tableItem.name,
                country: tableItem.country,
                currency: tableItem.currency,
                firstDatePointDate: tableItem.firstDatePointDate
                  ? new Date(tableItem.firstDatePointDate).toLocaleDateString('sv-SE')
                  : '',
                lastDataPointDate: tableItem.lastDataPointDate
                  ? new Date(tableItem.lastDataPointDate).toLocaleDateString('sv-SE')
                  : '',
                hidden: tableItem.hideReportFromUserReportList ? 'hidden' : '',
                isReportValid: <>{!tableItem.isReportValid && <div className="isValidError">Error in report!</div>}</>,
                actions: (
                  <>
                    <Link className="ml-3" to={routerPaths.reportEdit.replace(':reportUrl', tableItem.reportUrl)}>
                      <Button small type={ButtonType.button} buttonStyleType={ButtonStyleType.secondary} text="Edit" />
                    </Link>
                    <Link
                      className="ml-3"
                      to={routerPaths.report
                        .replace(':customerUrl', 'shared')
                        .replace(':reportUrl', tableItem.reportUrl)}
                    >
                      <Button small type={ButtonType.button} buttonStyleType={ButtonStyleType.secondary} text="View" />
                    </Link>
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.delete}
                      text="Unpublish"
                      onClick={() => confirmUnpublish(tableItem)}
                    />
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.delete}
                      text="X"
                      onClick={() => confirmDeleteReport(tableItem)}
                    />
                  </>
                ),
              }))
          }
          hasActions
          totalItems={reportsState.filter((i) => !i.isDraft).length}
        />
        <NewReportDialog
          showDialog={showNewReportDialogState}
          closeDialog={closeNewReportDialog}
          reportCreated={() => newReportCreated()}
          isShared={true}
        />
        <ConfirmDialog
          isLoading={confirmDialogState?.isLoading || false}
          errorMessage={confirmDialogState.errorMessage}
          submitDialog={confirmDialogState.submitDialog}
          showDialog={confirmDialogState.showDialog}
          closeDialog={closeConfirmAlertDialog}
          buttonTextAccept={confirmDialogState?.buttonTextAccept}
          dialogText={confirmDialogState?.dialogText}
        />
        <PrognosSnackbar
          title={snackbarState.title}
          message={snackbarState.message}
          showSnackbar={snackbarState.showSnackbar}
          snackbarType={SnackbarType.success}
        />
      </div>
    </>
  );
};

export default SharedReports;
